import logo from './logo.svg';
import './App.css';
import HeaderText from './Components/header';

function App() {  
  document.title = "PBPRO~";
  return (
    <div className="App">
      <header className="App-header">
        <p>
          <HeaderText />
        </p>
      </header>
    </div>
  );
}

export default App;
